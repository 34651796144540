export default [
  { name: 'Chicken Back (44%)', value: 44, key: 'chicken-back' },
  { name: 'Chicken Breast (20%)', value: 20, key: 'chicken-breast' },
  { name: 'Chicken Drumstick (27%)', value: 27, key: 'chicken-drumstick' },
  { name: 'Chicken Feet (60%)', value: 60, key: 'chicken-feet' },
  { name: 'Chicken Frame (80%)', value: 80, key: 'chicken-frame' },
  { name: 'Chicken Leg Quarter (30%)', value: 30, key: 'chicken-leg-quarter' },
  { name: 'Chicken Neck with Skin (36%)', value: 36, key: 'chicken-neck-with-wkin' },
  { name: 'Chicken Neck without Skin (50%)', value: 50, key: 'chicken-neck-without-skin' },
  { name: 'Chicken Thigh (21%)', value: 21, key: 'chicken-thigh' },
  { name: 'Chicken Whole (32%)', value: 32, key: 'chicken-whole' },
  { name: 'Chicken Wings (46%)', value: 46, key: 'chicken-wings' },
  { name: 'Duck Foot (60%)', value: 60, key: 'duck-foot' },
  { name: 'Duck Frame (80%)', value: 80, key: 'duck-frame' },
  { name: 'Duck Neck (50%)', value: 50, key: 'duck-neck' },
  { name: 'Duck Whole (28%)', value: 28, key: 'duck-whole' },
  { name: 'Duck Wing (39%)', value: 39, key: 'duck-wing' },
  { name: 'Pork Feet (30%)', value: 30, key: 'pork-feet' },
  { name: 'Pork Ribs (30%)', value: 30, key: 'pork-ribs' },
  { name: 'Pork Tail (30%)', value: 30, key: 'pork-tail' },
  { name: 'Rabbit Feet (40%)', value: 40, key: 'rabbit-feet' },
  { name: 'Rabbit Heads (75%)', value: 75, key: 'rabbit-heads' },
  { name: 'Rabbit Whole (28%)', value: 28, key: 'rabbit-whole' },
  { name: 'Turkey Neck (45%)', value: 45, key: 'turkey-neck' },
  { name: 'Custom', value: 0, key: 'custom' },
];

export const rmbLookup = {
  'chicken-back': 44,
  'chicken-breast': 20,
  'chicken-drumstick': 27,
  'chicken-feet': 60,
  'chicken-frame': 80,
  'chicken-leg-quarter': 30,
  'chicken-neck-with-wkin': 36,
  'chicken-neck-without-skin': 50,
  'chicken-thigh': 21,
  'chicken-whole': 32,
  'chicken-wings': 46,
  'duck-foot': 60,
  'duck-frame': 80,
  'duck-neck': 50,
  'duck-whole': 28,
  'duck-wing': 39,
  'pork-feet': 30,
  'pork-ribs': 30,
  'pork-tail': 30,
  'rabbit-feet': 40,
  'rabbit-heads': 75,
  'rabbit-whole': 28,
  'turkey-neck': 45,
  custom: 0,
};
