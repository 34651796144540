import { essentialNutrients } from "../constants/essentialNutrients";
import mapValues from "../utils/mapValues";

export const mapCalculatedNutrients = (nutrientList, increaseAmount) => {
  return mapValues(nutrientList, (nutrientItem) => {
    const newValue = {
      ...nutrientItem,
      adult: nutrientItem["adult"] * increaseAmount,
      puppy: nutrientItem["puppy"] * increaseAmount,
    };

    if (nutrientItem.min) {
      newValue.min = {
        // there is only an adult option currently
        adult: nutrientItem.min.adult * increaseAmount,
      };
    }
    return newValue;
  });
};

const getEssentialNutrients = (estimatedCalories) => {
  const nutrientPercentage = estimatedCalories / 1000;
  return mapCalculatedNutrients(essentialNutrients, nutrientPercentage);
};

export default getEssentialNutrients;
