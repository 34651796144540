const mapValues = (collection, mappingFn) => {
  const props = Object.entries(collection).map(([key, value]) => {
    return {
      [key]: mappingFn(value),
    };
  });

  return Object.assign(...props);
};

export default mapValues;
