export const essentialNutrients = {
  ala: {
    name: "ALA",
    // description: "Alpha-linolenic Acid",
    adult: 110,
    puppy: 200,
    unit: "mg",
  },
  "epa-plus-dha": {
    name: "EPA + DHA",
    // description: 'Eicosapentaenoic & Docosahexaenoic Acid'
    adult: 110,
    puppy: 130,
    unit: "mg",
  },
  iodine: {
    name: "Iodine",
    min: {
      adult: 175,
    },
    adult: 220,
    puppy: 220,
    unit: "mcg",
  },
  manganese: {
    name: "Manganese",
    adult: 1.2,
    puppy: 1.4,
    unit: "mg",
  },
  vitaminD: {
    name: "Vitamin D",
    adult: 3.4,
    puppy: 3.4,
    unit: "mcg",
  },
  vitaminE: {
    name: "Vitamin E",
    adult: 7.5,
    puppy: 7.5,
    unit: "mg",
  },
  zinc: {
    name: "Zinc",
    adult: 15,
    puppy: 25,
    unit: "mg",
  },
};
