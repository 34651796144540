const roundToPrecision = (n, d) => {
  d = d === undefined ? 2 : d;
  return parseFloat(n.toFixed(d));
};

const round = (amount, to = 2) => {
  return roundToPrecision(amount, to);
};

export default round;
